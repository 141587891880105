<template>
  <div>
    <div>
      <Table stripe size="small" max-height="720" :data="list" :columns="tableColumns" :loading="tableLoadding"></Table>
      <div class="paging_style">
        <Page :total="total" size="small" :page-size="query.pageSize" show-total show-elevator show-sizer
          :page-size-opts="pagesizeSetting" @on-page-size-change='changePageSize' :current="query.pageNumber"
          @on-change="handleChangePage"></Page>
      </div>
    </div>
    <Modal v-model="visiblePreview" width="800" :footer-hide="true">
      <p slot="header" class="text-center">{{ fileName }} {{ variableTitle }}</p>
      <Row class="m-b-5">
        <i-col class="text-right">
          <Button size="small" type="success" @click="viewBigImage">查看原图</Button>
        </i-col>
      </Row>
      <img-light-box ref="imgLightBox" :showModal="visiblePreview" :sortIndex.sync="sortIndex"></img-light-box>
    </Modal>
  </div>
</template>

<script>
import { getTaskDetailPage } from '@/api/msp/program'
import ImgLightBox from '@/components/common/ImgVideoLightBox'
export default {
  components: { ImgLightBox },
  data () {
    return {
      visiblePreview: false,
      sortIndex: 0,
      fileName: '',
      variableTitle: '',
      pagesizeSetting: [15, 50, 100, 200, 500],
      query: {
        pageNumber: 1,
        pageSize: 15,
        programUniqueCode: ''
      },
      list: [],
      total: 0,
      tableLoadding: false,
      tableColumns: [
        { title: this.taskType === 11 ? '任务名称' : '资源编号', key: 'resourceCode', minWidth: 100, align: 'center' },
        { title: '资源类型', key: 'resourcetypeName', minWidth: 100, align: 'center' },
        { title: '所属资产', key: 'assetName', minWidth: 100, align: 'center' },
        { title: '所属站台', key: 'stationName', minWidth: 100, align: 'center' },
        { title: '任务类型', key: 'typeName', minWidth: 100, align: 'center' },
        { title: '面数', key: 'side', minWidth: 60, align: 'center' },
        {
          title: '发起公司',
          key: 'createCompanyName',
          minWidth: 100,
          align: 'center',
          render: (h, { row }) => {
            return h('span', row.createCompanyName || '/')
          }
        },
        {
          title: '单品',
          key: 'skuName',
          minWidth: 100,
          align: 'center',
          render: (h, params) => {
            return h('span', params.row.skuId > -1 ? params.row.skuName : '/')
          }
        },
        {
          title: '作业日期',
          key: 'actionDate',
          minWidth: 120,
          align: 'center',
          render: (h, params) => {
            const html = []
            if (new Date(params.row.actionDate) <= new Date(params.row.publishingEndDate) && params.row.status === 0 && [1, 7].includes(this.taskType) && !this.elecDeviceIds.includes(params.row.resourcetypeId)) {
              // 派单中状态，当前作业日期小于等于正在发布的档期结束日期。排除电子屏。
              html.push(
                h('Tooltip', {
                  style: {
                    cursor: 'pointer'
                  },
                  props: {
                    content: `此资源正在发布中的结束日期为：${params.row.publishingEndDate || '/'}，请重新选择作业日期！`,
                    placement: 'right',
                    maxWidth: 200,
                    transfer: true
                  }
                }, [
                  h('span', {
                    style: {
                      color: '#ff6600'
                    }
                  }, params.row.actionDate),
                  h('Icon', {
                    props: {
                      type: 'md-help-circle',
                      color: '#ff6600',
                      size: 14
                    }
                  })
                ])
              )
            } else {
              html.push(
                h('p', params.row.actionDate)
              )
            }

            html.push(
              h('Tag', {
                props: { color: params.row.actionDateModifyStatus === 1 ? 'default' : 'primary' }
              }, params.row.actionDateModifyStatusName)
            )
            return h('div', html)
          }
        },
        { title: '规格(秒)', key: 'unitDuration', align: 'center', minWidth: 80 },
        { title: '投放次数', key: 'playTimes', align: 'center', minWidth: 100 },
        { title: '总时长(秒)', key: 'tduration', align: 'center', minWidth: 100 },
        {
          title: '成本项',
          key: 'serviceName',
          align: 'center',
          minWidth: 100,
          render: (h, params) => {
            return h('span', params.row.serviceName === '' ? '/' : params.row.serviceName)
          }
        },
        {
          title: '计费',
          align: 'center',
          minWidth: 160,
          render: (h, params) => {
            if (params.row.taskitemCostVOList.length) {
              const html = []
              params.row.taskitemCostVOList.forEach(item => {
                html.push(h('p', `${item.chargeTypeName}:${item.cost}元/${item.unitName}`))
              })
              return h('div', html)
            } else {
              return h('span', '/')
            }
          }
        },
        { title: '用途', key: 'adTypeName', align: 'center', minWidth: 80 },
        // {
        //   title: '投放时间',
        //   align: 'center',
        //   minWidth: 140,
        //   render: (h, params) => {
        //     return h('span', `${params.row.startTime} - ${params.row.endTime}`)
        //   }
        // },
        {
          title: '作业画面',
          minWidth: 100,
          align: 'center',
          render: (h, params) => {
            if (params.row.taskitemFiles && params.row.taskitemFiles.length > 0) {
              if (params.row.taskitemFiles.some(x => (x.mimeType.includes('video') || x.mimeType.includes('audio')))) {
                return h('a', {
                  on: {
                    click: () => {
                      const postData = {
                        startIndex: 0,
                        imgList: params.row.taskitemFiles
                      }
                      this.sortIndex = 0
                      this.fileName = params.row.stationName
                      this.$refs.imgLightBox.init(postData)
                      this.visiblePreview = true
                    }
                  },
                  style: {
                    cursor: 'pointer'
                  }
                }, '查看素材')
              } else {
                return h('div', [
                  h(
                    'img',
                    {
                      domProps: {
                        src: params.row.taskitemFiles[0].fileKey + '?x-oss-process=image/resize,w_64'
                      },
                      on: {
                        click: () => {
                          const postData = {
                            startIndex: 0,
                            imgList: params.row.taskitemFiles
                          }
                          this.sortIndex = 0
                          this.fileName = `${params.row.domainName} ${params.row.code}`
                          this.variableTitle = ''
                          this.curShowFileArray = params.row.taskitemFiles
                          this.$refs.imgLightBox.init(postData)
                          this.visiblePreview = true
                        }
                      },
                      style: {
                        width: '64px',
                        cursor: 'pointer'
                      }
                    }
                  )
                ])
              }
            } else {
              return h('span', '暂无画面')
            }
          }
        },
        {
          title: '作业名称',
          minWidth: 100,
          align: 'center',
          render: (h, params) => {
            const name = Array.from(new Set(params.row.taskitemFiles.map(x => x.fileName))).toString()
            return h('Tooltip', {
              props: {
                content: name,
                transfer: true,
                'max-width': '200px'
              }
            }, name.length > 12 ? name.substr(0, 11) + '...' : name)
          }
        },
        {
          title: '画面来源',
          align: 'center',
          minWidth: 100,
          render: (h, params) => {
            return h('div', [
              h('p', params.row.picturesourceName),
              params.row.isReuseOldFile ? h('p', {
                style: { color: '#ff9900' }
              }, '使用已有画面') : null
            ])
          }
        },
        {
          title: '供应商',
          align: 'center',
          minWidth: 100,
          render: (h, params) => {
            return h('span', params.row.taskitemLabor.companyName)
          }
        },
        // {
        //   title: '状态',
        //   minWidth: 120,
        //   align: 'center',
        //   render: (h, params) => {
        //     const html = [
        //       formartMspTaskitemStatus(h, params.row.status, params.row.statusName),
        //       formartMspTaskitemRephotoStatus(h, params.row.rephoto)
        //     ]
        //     if (params.row.hangUpStatus === 2) { // 挂起
        //       html.push(h('div', [
        //         h('Tag', {
        //           props: {
        //             color: 'warning'
        //           }
        //         }, '已挂起')
        //       ]))
        //     }
        //     return h('div', html)
        //   }
        // },
        {
          title: '备注',
          align: 'center',
          minWidth: 100,
          render: (h, params) => {
            const remark = `${params.row.remark} ${params.row.extra}`
            return h('Tooltip', {
              props: {
                content: remark,
                transfer: true,
                'max-width': '200px'
              }
            }, remark.length > 12 ? remark.substring(0, 11) + '...' : remark)
          }
        }
      ]
    }
  },
  computed: {
    uniqueCode () {
      return this.$store.state.workOrder.uniqueCode
    }
  },
  destroyed () {
    this.$store.commit('set_unique_code', '')
  },
  methods: {
    getTableData () {
      this.tableLoadding = true
      this.query.programUniqueCode = this.uniqueCode
      getTaskDetailPage(this.query).then(res => {
        if (res && !res.errcode) {
          this.list = res.list
          this.total = res.totalRow
          this.query.pageNumber = res.pageNumber
        } else {
          this.list = []
          this.total = 0
          this.query.pageNumber = 1
        }
      }).finally(() => { this.tableLoadding = false })
    },
    changePageSize (pagesize) {
      this.query.pageNumber = 1
      this.query.pageSize = pagesize
      this.getTableData()
    },
    handleChangePage (curPage) {
      this.query.pageNumber = curPage
      this.getTableData()
    },
    /**
     * 查看原图
     */
    viewBigImage () {
      this.$refs.imgLightBox.viewBigImg()
    }
  },
  watch: {
    uniqueCode: {
      deep: true,
      immediate: true,
      handler (val) {
        if (val) {
          this.getTableData()
        }
      }
    }
  }
}
</script>
